var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-activity-female",class:{
		'step-2': _vm.landingStep === 2,
		'landing-activity-female_pilates': _vm.IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL && _vm.landingStep === 2,
	}},[(_vm.landingStep === 1)?_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('transition',{attrs:{"appear":"","name":"slide-up"}},[_c('div',[_c('div',{staticClass:"landing-activity-female__title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"landing-activity-female__subtitle"},[_c('i18n',{attrs:{"path":"female_activity.pages.landing.step_1.subtitle"},scopedSlots:_vm._u([{key:"age",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.$t('female_activity.pages.landing.step_1.age')))])]},proxy:true}],null,false,1166414555)})],1)])]),_c('transition',{attrs:{"appear":"","name":"slide-up-1"}},[_c('div',[_c('div',{staticClass:"landing-activity-female__grid"},_vm._l((_vm.ageRanges),function(ageRange){return _c('div',{key:ageRange.value,staticClass:"landing-activity-female__age-item",class:{ active: ageRange.value === _vm.activeValue },on:{"click":function($event){return _vm.onClick(ageRange)}}},[_c('img',{staticClass:"landing-activity-female__age-image",attrs:{"src":ageRange.img,"alt":""}}),_c('div',{staticClass:"landing-activity-female__age-item-top"}),_c('div',{staticClass:"landing-activity-female__age-item-bottom"},[_c('div',{staticClass:"landing-activity-female__age-item-text"},[_vm._v(_vm._s(ageRange.label))]),_c('div',{staticClass:"landing-activity-female__age-item-icon"},[_c('img',{attrs:{"height":"24","src":require("@/assets/landing-activity-female/chevron-right.png"),"alt":""}})])])])}),0),(
						_vm.IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL ||
						_vm.IS_FEMALE_ACTIVITY_SOMATIC_YOGA_OB_FUNNEL ||
						_vm.IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL
					)?_c('div',{staticClass:"landing-activity-female__other-ages"},[_c('button',{staticClass:"landing-activity-female__age-18 btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClick({ value: 18, analyticValue: '18-39' })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('female_yoga.pages.landing.im_young')))]),_c('i',{staticClass:"icon-arrow-right"})])]):_vm._e()])])],1):_c('div',{staticClass:"landing-activity-female__step-2-container"},[_c('div',{staticClass:"container-fluid landing-activity-female__step-2-wrapper"},[_c('div',{staticClass:"landing-activity-female__women-header"},[_vm._m(1),_c('div',{staticClass:"landing-activity-female__women-header-middle"},[_c('div',{staticClass:"landing-activity-female__women-header-title"},[_vm._v(" "+_vm._s(_vm.$t('female_activity.pages.landing.step_2.title'))+" ")]),_c('div',{staticClass:"landing-activity-female__women-header-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('female_activity.pages.landing.step_2.subtitle', { age: _vm.ageDecade, }))+" ")])]),_vm._m(2)]),_c('div',{staticClass:"landing-activity-female__header-text"},[_vm._v(_vm._s(_vm.step2Subtitle))]),_c('img',{staticClass:"landing-activity-female__women-image",attrs:{"src":_vm.step2ImageSrc,"alt":"women"}}),_c('div',{staticClass:"landing-activity-female__step-2-spacer"})]),_c('div',{staticClass:"landing-activity-female__button-container"},[_c('div',{staticClass:"container-fluid"},[_c('button',{staticClass:"btn btn-block btn-primary",on:{"click":_vm.onStep2Click}},[_vm._v(" "+_vm._s(_vm.$t('female_activity.pages.landing.step_2.cta'))+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-activity-female__header"},[_c('img',{staticClass:"landing-activity-female__header-logo",attrs:{"src":require("@/assets/logos/logo-gray-inline.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-activity-female__women-header-left"},[_c('img',{attrs:{"src":require("@/assets/landing-activity-female/title-left.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-activity-female__women-header-right"},[_c('img',{attrs:{"src":require("@/assets/landing-activity-female/title-right.png"),"alt":""}})])
}]

export { render, staticRenderFns }